import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as icons from '@element-plus/icons'
import focusable from 'vue-tv-focusable'

const app = createApp(App)

Object.keys(icons).forEach(key => {
    app.component(key, icons[key])
})

app.use(router)
    .use(ElementPlus)
    .use(focusable)

app.mount('#app')
