<template>
  <div style="height: 100%">
    <el-aside v-if="currentFullPath.indexOf('/play') === -1">
      <el-menu
          v-if="currentFullPath !== '/play'"
          text-color="#6182c7"
          active-text-color="white"
          style="position: fixed; top: 0; left: 0; width: 140px; padding: 10px; height: 100vh; overflow-y: auto;"
      >
        <el-menu-item @click="onMenuItemClick('/')" v-focusable index="1" :class="checkPath('/') ? 'menu-activate' : ''">
          <el-icon :size="checkPath('/')  ? 28 : 20">
            <svg t="1708833568817" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5120" width="256" height="256"><path d="M949.082218 519.343245 508.704442 107.590414 68.326667 518.133697c-8.615215 8.03193-9.096169 21.538549-1.043772 30.144554 8.043187 8.599865 21.566178 9.085936 30.175253 1.035586l411.214573-383.337665 411.232992 384.505257c4.125971 3.854794 9.363252 5.760191 14.5903 5.760191 5.690606 0 11.384281-2.260483 15.58393-6.757914C958.138478 540.883841 957.695387 527.388479 949.082218 519.343245L949.082218 519.343245zM949.082218 519.343245M814.699602 527.800871c-11.787464 0-21.349237 9.555633-21.349237 21.327748l0 327.037405L622.552373 876.166023 622.552373 648.662543 394.824789 648.662543l0 227.503481L224.032938 876.166023 224.032938 549.128619c0-11.772115-9.55154-21.327748-21.348214-21.327748-11.802814 0-21.35333 9.555633-21.35333 21.327748l0 369.691877 256.19494 0L437.526333 691.318038l142.329613 0 0 227.502457 256.1888 0L836.044746 549.128619C836.045769 537.356504 826.481949 527.800871 814.699602 527.800871L814.699602 527.800871zM814.699602 527.800871M665.254941 222.095307l128.095423 0 0 113.74867c0 11.789511 9.562796 21.332864 21.349237 21.332864 11.783371 0 21.346167-9.543354 21.346167-21.332864L836.045769 179.439812 665.254941 179.439812c-11.789511 0-21.35333 9.538237-21.35333 21.327748C643.900587 212.554 653.464407 222.095307 665.254941 222.095307L665.254941 222.095307zM665.254941 222.095307" p-id="5121"></path></svg>
          </el-icon>
          <template #title>
            首页
          </template>
        </el-menu-item>
        <el-menu-item @click="onMenuItemClick('/search')" style="margin-top: 10px" v-focusable index="2" :class="checkPath('/search') ? 'menu-activate' : ''">
          <el-icon :size="checkPath('/search') ? 28 : 20">
            <svg t="1708834124641" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6121" width="256" height="256"><path d="M453.818182 23.272727C704.465455 23.272727 907.636364 226.443636 907.636364 477.090909c0 35.746909-4.142545 70.981818-12.264728 105.192727a46.545455 46.545455 0 0 1-90.577454-21.504c6.469818-27.182545 9.751273-55.202909 9.751273-83.688727C814.545455 277.876364 653.032727 116.363636 453.818182 116.363636 254.603636 116.363636 93.090909 277.876364 93.090909 477.090909 93.090909 676.305455 254.603636 837.818182 453.818182 837.818182c101.003636 0 195.211636-41.658182 262.981818-113.826909a46.545455 46.545455 0 0 1 63.650909-3.979637c2.978909 1.792 5.818182 3.956364 8.401455 6.446546l213.201454 205.684363a45.707636 45.707636 0 0 1 0.581818 65.233455l-0.581818 0.581818a46.545455 46.545455 0 0 1-65.233454 0.581818l-185.390546-178.827636A452.305455 452.305455 0 0 1 453.818182 930.909091C203.170909 930.909091 0 727.738182 0 477.090909S203.170909 23.272727 453.818182 23.272727z m76.613818 179.828364c86.714182 23.598545 147.2 76.078545 177.245091 154.973091a46.545455 46.545455 0 0 1-86.993455 33.140363c-19.176727-50.362182-55.994182-82.315636-114.688-98.280727a46.545455 46.545455 0 1 1 24.436364-89.832727z" p-id="6122"></path></svg>
          </el-icon>
          <template #title>搜索</template>
        </el-menu-item>
        <el-menu-item style="margin-top: 10px" v-focusable index="3" :class="checkPath('/television') ? 'menu-activate' : ''">
          <el-icon :size="checkPath('/television') ? 28 : 20">
            <svg t="1708834366687" class="icon" viewBox="0 0 1199 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1689" width="256" height="256"><path d="M1082.581236 859.97254H117.162471A117.162471 117.162471 0 0 1 0 742.810069V117.162471A117.162471 117.162471 0 0 1 117.162471 0h965.418765a117.162471 117.162471 0 0 1 117.162471 117.162471v625.647598a117.162471 117.162471 0 0 1-117.162471 117.162471zM117.162471 93.729977a23.432494 23.432494 0 0 0-23.432494 23.432494v625.647598a23.432494 23.432494 0 0 0 23.432494 23.432494h965.418765a23.432494 23.432494 0 0 0 23.432494-23.432494V117.162471a23.432494 23.432494 0 0 0-23.432494-23.432494zM890.434783 1024H309.308924a46.864989 46.864989 0 1 1 0-93.729977h581.125859a46.864989 46.864989 0 1 1 0 93.729977z" fill="" p-id="1690"></path><path d="M599.871854 1007.597254a46.864989 46.864989 0 0 1-46.864989-46.864989V829.510297a46.864989 46.864989 0 1 1 93.729977 0v131.221968a46.864989 46.864989 0 0 1-46.864988 46.864989zM590.498856 374.919908H301.107551a46.864989 46.864989 0 0 1 0-93.729977h289.391305a46.864989 46.864989 0 1 1 0 93.729977z" fill="" p-id="1691"></path><path d="M342.114416 647.908467l-15.231121-2.34325a46.864989 46.864989 0 0 1-28.118993-59.75286l97.244851-272.988558a46.864989 46.864989 0 1 1 87.871854 31.633867l-97.244851 271.816934a46.864989 46.864989 0 0 1-44.52174 31.633867zM697.116705 647.908467l-16.402746-3.514874a46.864989 46.864989 0 0 1-30.462243-43.350115V328.05492a46.864989 46.864989 0 0 1 93.729977 0v149.967963l159.340961-181.60183a46.897794 46.897794 0 1 1 70.297483 62.096109L732.265446 632.677346a46.864989 46.864989 0 0 1-35.148741 15.231121zM1024 742.810069h-63.267735a46.864989 46.864989 0 0 1 0-93.729977h63.267735a46.864989 46.864989 0 0 1 0 93.729977z" p-id="1692"></path></svg></el-icon>
          <template #title>电视</template>
        </el-menu-item>
        <el-menu-item @click="clickCategory('1')" style="margin-top: 10px" v-focusable index="4" :class="checkPath('/category?id=1') ? 'menu-activate' : ''">
          <el-icon :size="checkPath('/category?id=1') ? 28 : 20">
            <svg t="1707531706710" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2698" width="256" height="256"><path d="M844.832 885.344c-30.485333-20.714667-77.781333-18.965333-144.053333 9.386667A425.024 425.024 0 0 1 512 938.666667C276.362667 938.666667 85.333333 747.637333 85.333333 512S276.362667 85.333333 512 85.333333s426.666667 191.029333 426.666667 426.666667a425.013333 425.013333 0 0 1-44.405334 189.717333 32 32 0 0 1-57.301333-28.490666A361.013333 361.013333 0 0 0 874.666667 512c0-200.298667-162.368-362.666667-362.666667-362.666667S149.333333 311.701333 149.333333 512s162.368 362.666667 362.666667 362.666667c56.704 0 111.488-13.013333 161.12-37.653334l1.6-0.746666c84.746667-36.437333 154.176-39.125333 206.08-3.861334a32 32 0 1 1-35.968 52.938667zM512 416a64 64 0 1 1 0-128 64 64 0 0 1 0 128z m160 160a64 64 0 1 1 0-128 64 64 0 0 1 0 128zM512 736a64 64 0 1 1 0-128 64 64 0 0 1 0 128zM352 576a64 64 0 1 1 0-128 64 64 0 0 1 0 128z" p-id="2699"></path></svg>
          </el-icon>
          <template #title>电影</template>
        </el-menu-item>
        <el-menu-item @click="clickCategory('2')" style="margin-top: 10px" v-focusable index="5" :class="checkPath('/category?id=2') ? 'menu-activate' : ''">
          <el-icon :size="checkPath('/category?id=2') ? 28 : 20">
            <svg t="1708841057809" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9358" width="256" height="256"><path d="M928 832l-832 0C43.072 832 0 788.992 0 736l0-576C0 107.072 43.072 64 96 64l832 0C980.864 64 1024 107.072 1024 160l0 576C1024 788.992 980.864 832 928 832zM96 128C78.4 128 64 142.336 64 160l0 576C64 753.6 78.4 768 96 768l832 0c17.6 0 32-14.4 32-32l0-576C960 142.336 945.6 128 928 128L96 128z" p-id="9359"></path><path d="M192 896l640 0 0 64-640 0 0-64Z" p-id="9360"></path><path d="M444.672 325.312C410.304 307.008 384 320.832 384 356.032l0 185.344C384 576.64 410.24 590.4 444.48 571.904l167.04-90.304c34.112-18.432 34.624-48.512 0.256-66.816L444.672 325.312z" p-id="9361"></path></svg>
          </el-icon>
          <template #title>剧集</template>
        </el-menu-item>
        <el-menu-item @click="clickCategory('3')" style="margin-top: 10px" v-focusable index="6" :class="checkPath('/category?id=3') ? 'menu-activate' : ''">
          <el-icon :size="checkPath('/category?id=3') ? 28 : 20">
            <svg t="1708841097194" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12106" width="256" height="256"><path d="M586.8 570.8c-0.5-1.6-0.1-3.3 0.9-4.5l106.4-127.2c8.7-10.4 10.7-24.5 5.2-36.9-5.5-12.4-17.3-20.4-30.8-21l-166-7c-1.6-0.1-3-0.9-3.9-2.3l-88.3-140.6c-7.2-11.5-20-17.8-33.5-16.4-13.5 1.3-24.8 10.1-29.5 22.8l-57.9 155.6c-0.5 1.5-1.8 2.6-3.4 3l-161 40.5c-13.1 3.3-23.1 13.6-25.9 26.8-2.9 13.2 2 26.7 12.6 35.1l130.1 103.1c1.3 1 2 2.6 1.9 4.2l-11.2 165.7c-0.7 10.4 3.2 20.5 10.6 27.7 11.7 11.4 30.4 13.3 44.1 4.1l137.2-91.1c0.7-0.5 1.6-0.8 2.5-0.9l2-0.2c0.8-0.1 1.7 0.1 2.4 0.4L584.2 773c5.5 2.2 11.1 3 16.8 2.4 10.4-1.1 19.7-6.6 25.6-15.4 5.9-8.6 7.6-19.2 4.8-29.3l-44.6-159.9z m14.7 172.1c-0.8 1.3-2.2 2.2-3.6 2.3L441.5 683c-5.3-2.2-11.1-3-16.8-2.4-5.7 0.6-11.2 2.6-16 5.7L268.2 779c-1.4 0.1-2.9-0.4-4-1.5-1.1-1-1.5-2.3-1.4-3.8L274 608c0.8-11.6-4.2-22.8-13.3-30l-130-103.1c-2.1-1.7-2-3.8-1.8-4.9 0.2-1.1 1-3 3.6-3.7l160.9-40.5c11.3-2.8 20.5-11 24.6-21.9l57.9-155.7c1.8-5 5.9-5.6 8.8-0.9L473 387.9c6.2 9.9 16.9 16.1 28.4 16.5l165.9 7c2.7 0.1 3.8 1.8 4.3 2.9 0.5 1 1 3-0.7 5.1L564.4 546.9c-7.4 8.9-10 20.9-6.9 32.1l44.6 159.9c0.4 1.5 0.2 2.8-0.6 4z" p-id="12107"></path><path d="M906.1 652.1L845 622.6l-10.3-67c-1.8-11.7-11.7-20.2-23.6-20.2-6.6 0-12.7 2.7-17.2 7.4l-46.9 49-66.9-10.8c-9.2-1.5-19.5 3.3-24.3 11.3-4.3 7.2-4.5 16-0.5 23.5l32.1 59.7-31 60.3c-3.8 7.5-3.6 16.1 0.8 23.3 4.4 7.2 12 11.5 20.3 11.5 0.9 0 3.4-0.3 4.3-0.4l66.7-12.2 47.8 48.3c4.6 4.5 10.6 7 16.9 7 11.9 0 22-8.9 23.6-20.7l9.2-67.2 60.5-30.6c8.2-4.1 13.2-12.4 13.1-21.5s-5.3-17.2-13.5-21.2z m-77 47.9c-7 3.5-11.8 10.3-12.8 18l-7.8 57.4-40.8-41.1c-4.4-4.5-10.6-7.1-16.9-7.1-0.9 0-3.4 0.2-4.2 0.4L689.5 738l26.5-51.5c3.6-6.9 3.5-15.2-0.2-22.2l-27.4-51.1 57.3 9.3c0.8 0.1 2.9 0.3 3.7 0.3 6.5 0 12.7-2.7 17.2-7.4l40.1-41.9 8.8 57.4c1.2 7.7 6.1 14.4 13.2 17.8l52.2 25.2-51.8 26.1z" p-id="12108"></path></svg>
          </el-icon>
          <template #title>综艺</template>
        </el-menu-item>
        <el-menu-item @click="clickCategory('4')" style="margin-top: 10px" v-focusable index="7" :class="checkPath('/category?id=4') ? 'menu-activate' : ''">
          <el-icon :size="checkPath('/category?id=4') ? 28 : 20">
            <svg t="1708841305049" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="17566" width="256" height="256"><path d="M662.016 593.408c-5.632 0-10.752 2.048-15.36 5.12-38.4 30.208-84.992 46.592-134.656 46.592s-96.256-16.384-134.656-46.592c-4.096-3.584-9.728-5.12-15.36-5.12-23.04 0-33.28 29.184-14.848 43.52 45.568 35.84 102.912 57.344 164.864 57.344s119.808-21.504 164.864-57.344c18.432-14.336 8.192-43.52-14.848-43.52z" p-id="17567"></path><path d="M510.464 874.496c-186.368 0-336.384-151.552-336.384-336.384 0-26.624 2.56-51.2 8.192-76.288C124.416 435.712 87.04 378.88 87.04 313.344c0-90.112 73.728-163.84 163.84-163.84 56.832 0 108.544 29.184 137.728 75.264 38.912-15.36 80.384-23.552 123.392-23.552s84.48 8.192 123.392 23.552c29.184-47.104 80.384-75.264 137.728-75.264 90.112 0 163.84 73.728 163.84 163.84 0 65.536-38.912 122.368-95.744 148.48 5.632 25.088 8.192 51.2 8.192 76.288-2.56 184.832-152.576 336.384-338.944 336.384zM249.344 204.8C189.44 204.8 140.8 253.44 140.8 313.344c0 50.176 33.28 91.648 81.92 103.936 7.168 1.536 13.824 7.168 17.92 13.824 4.096 7.168 4.096 15.36 2.56 22.016-8.192 27.648-13.824 56.832-13.824 86.016 0 155.648 126.464 280.576 280.576 280.576s280.576-128 280.576-282.112c0-29.184-4.096-58.368-13.824-86.016-2.56-7.168-1.536-15.36 2.56-22.016 4.096-7.168 9.728-11.264 17.92-13.824 47.104-12.288 81.92-55.808 81.92-103.936 0-59.904-48.64-108.544-108.544-108.544-43.008 0-83.456 26.624-99.84 66.56-2.56 7.168-8.192 12.288-15.36 15.36-7.168 2.56-15.36 2.56-22.016-1.536-38.912-19.456-80.384-29.184-123.392-29.184s-84.48 9.728-123.392 29.184c-7.168 2.56-15.36 4.096-22.016 1.536-7.168-2.56-12.288-8.192-15.36-15.36C332.8 229.888 293.888 204.8 249.344 204.8z" p-id="17568"></path></svg>
          </el-icon>
          <template #title>动漫</template>
        </el-menu-item>
        <el-menu-item style="margin-top: 10px" v-focusable index="8" :class="checkPath('/history') ? 'menu-activate' : ''">
          <el-icon :size="checkPath('/history') ? 28 : 20">
            <svg t="1708841363135" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="18611" width="256" height="256"><path d="M511.73808594 93.19941406c-140.67070313 0-264.92695313 69.1453125-341.01035156 175.08955078v-70.47597656c0-21.66767578-17.61679688-39.22998047-39.346875-39.22998047s-39.346875 17.56318359-39.346875 39.22998047v156.91992188c0 21.66240234 17.61679688 39.22998047 39.346875 39.22998047h170.50341796c21.73007813 0 39.346875-17.56669922 39.346875-39.22998047 0-21.66767578-17.61679688-39.22998047-39.346875-39.22998047h-68.4421875c61.73085937-86.98798828 163.28496094-143.84267578 278.296875-143.84267578 188.32851562 0 341.00507812 152.22128906 341.00507813 339.99345703 0 187.76865234-152.6765625 339.99345703-341.00507813 339.99345703-158.73574219 0-291.74589844-108.29179688-329.75771484-254.70439453l-0.68291016 0.21269531c-5.3015625-15.41162109-19.54248047-26.65986328-36.8024414-26.65986328-21.73007813 0-39.346875 17.56318359-39.346875 39.22558594 0 3.86630859 1.16367187 7.36875 2.20605469 10.89316406l-0.40957032 0.12919922c0.25839844 0.95009766 0.73212891 1.8 0.99580078 2.74482422 0.28916016 0.76201172 0.48164063 1.52314453 0.81650391 2.2640625 49.92539062 175.47011719 210.98935547 304.35380859 402.98378906 304.35380859 231.79394531 0 419.69970703-187.35029297 419.69970703-418.45341797-0.00175781-231.10751953-187.90839844-418.45429688-419.70322265-418.45429687z m0 176.53183594c-21.73007813 0-39.346875 17.56318359-39.346875 39.22998047v235.37988281c0 21.66767578 17.61679688 39.22998047 39.346875 39.22998047h170.50253906c21.73007813 0 39.346875-17.56318359 39.346875-39.22558594 0-21.67119141-17.61679688-39.22998047-39.346875-39.22998047H551.08496094v-196.15429687c0-21.66767578-17.61767578-39.22998047-39.34775391-39.22998047z" p-id="18612"></path></svg>
          </el-icon>
          <template #title>历史</template>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main style="padding: 0" :class="currentFullPath.indexOf('/play') !== -1 ? 'main-content-play' : 'main-content'">
      <router-view :key="$route.path" />
    </el-main>
  </div>
</template>

<script>
import {defineComponent, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";


export default defineComponent({
  data() {
    return {
      currentFullPath: '/',
    }
  },
  setup() {
    const router = useRouter();
    window.goBack = () => {
      if (window.history.length > 1) {
        router.go(-1)
        // return true;
      } else {
        return false;
      }
      return true
    }

  },
  methods: {
    checkPath(path) {
      return this.currentFullPath === path
    },
    onMenuItemClick(route) {
      this.$router.push(route)
    },
    clickCategory(id) {
      if (this.currentFullPath.indexOf('/category') !== -1) {
        location.href = '/category?id=' + id;
      }
      else {
        this.$router.push('/category?id=' + id)
      }
    },
  },
  mounted() {
    console.log(this.currentFullPath.indexOf('/play'))
    // 全局背景颜色
    document
        .querySelector("body")
        .setAttribute("style", "background-color: whitesmoke");

    // ElementPlus主题色
    document.body.style.setProperty('--el-color-primary', '#6182c7');
    document.body.style.setProperty('--el-color-primary-light-9', '#F5FBF0');
    document.body.style.setProperty('--el-color-primary-light-3', '#759df1');

    // 取消margin
    document.body.style.setProperty('margin', '0');

    //
    const route = useRoute();

    // 观察路由变化并更新currentPath
    watch(() => route.fullPath, (newPath) => {
      this.currentFullPath = newPath
    });
  }
})
</script>

<style lang="scss" scoped>

.menu-activate {
  font-weight: bold;
  background: #809FD9;
  border-radius: 5px;
  font-size: 16px;
  color: white;
}

.focus {
  position: relative;
  border: 1px dashed #6182c7;
  border-radius: 5px;
  transition: all .3s;
  color: #6182c7;
}

.focus::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid #6182c7;
  border-radius: 5px;
  animation: div5Ani 3s infinite linear;
}

.focus::after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid #6182c7;
  border-radius: 5px;
  animation: div5Ani 3s infinite linear;

}

@keyframes div5Ani {
  0%,
  100% {
    clip-path: inset(0 0 98% 0);
  }

  25% {
    clip-path: inset(0 98% 0 0);
  }
  50% {
    clip-path: inset(98% 0 0 0);
  }
  75% {
    clip-path: inset(0 0 0 98%);
  }
}

.focus::after {
  animation: div5Ani 3s infinite -1.5s linear;
}

.main-content {
  padding: 10px;
  margin-left: 140px;
}

.main-content-play {

}
</style>
