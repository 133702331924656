import { createRouter, createWebHistory } from 'vue-router'
import {ElLoading} from "element-plus";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import("@/views/IndexView.vue"),
            meta: { title: '睿蒙影视' }
        },
        {
            path: '/play',
            name: 'play',
            component: () => import("@/views/PlayView.vue"),
            meta: { title: '睿蒙影视' }
        },
        {
            path: '/category',
            name: 'category',
            component: () => import("@/views/CategoryView.vue"),
            meta: { title: '睿蒙影视 | 分类' }
        },
        {
            path: '/search',
            name: 'search',
            component: () => import("@/views/SearchView.vue"),
            meta: { title: '睿蒙影视 | 搜索' }
        },
        {
            path: '/error',
            name: 'error',
            component: () => import("@/views/ErrorView.vue"),
        },
    ]
})

router.beforeEach((to, from, next) => {
    // let loading = ElLoading.service({
    //     lock: true,
    //     text: '加载中...',
    //     background: 'rgba(255, 255, 255, 0.95)'
    // });
    // 设置标题
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    // 如果没有找到路由，跳转到404页面
    if (to.matched.length === 0) {
        next('/error?errorType=404');
        loading.close()
    }
    window.scrollTo(0, 0)

    next();
    // loading.close()
})

export default router
